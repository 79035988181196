import { defineStore } from "pinia";

export const usePPOStore = defineStore("ppo", {
  state: () => ({
    isLoading: false,
    items: [] as CartItem[],
    merchantItemsMid: null as any,
    totalOrder: 0,
    product: null as any,
    prodItems: [] as any[],
    productItem: null as any,
    promo: {} as PostPurchaseOffer,
    warehouse: null as any,
    selected: null as any,
  }),
  actions: {
    async checkPurcacheOffer() {
      const { PROMOSVC } = useRuntimeConfig().public;
      const payload = {
        items: useCartStore().items.map((item: any) => {
          return {
            item_id: item.item_id,
            qty: item.qty,
            is_custom: item.is_custom,
          };
        }),
      };
      // console.log("getShippingPromo merchant_id", payload);
      const { data, error } = await useFlikApi<ApiResponse>(
        `${PROMOSVC}/v1/ppos/offer`,
        { method: "POST", body: payload },
      );
      const ppoData: any = data?.value?.data || null;
      console.log("ppoData data:", ppoData);
      const ppoErrors = error?.value?.data?.errors || [];
      console.log("ppoErrors error:", ppoErrors);

      if (ppoData && ppoErrors.length === 0) {
        if (ppoData?.product && ppoData?.promo) {
          this.product = ppoData.product;
          this.promo = ppoData.promo;
          this.warehouse = ppoData.default_warehouse;
          this.productItem = this.product.items[0];

          this.selected = {
            ...this.productItem,
            itemOption: this.productItem.varoptions[0].toLowerCase(),
          };

          const prodItems = ppoData.product.items.map((item: any) => {
            return {
              ...item,
              itemOption: item.varoptions[0].toLowerCase(),
            };
          });

          if (hasLength(prodItems)) this.prodItems.push(...prodItems);

          this.setItems();

          useUiStore().modalPurchaseOffer = true;
        } else {
          useUiStore().modalPurchaseOffer = false;
          this.$reset();
        }
        this.isLoading = false;
        return true;
      }
      if (ppoErrors.length > 0) {
        useNotification().notify({
          type: "error",
          text: ppoErrors[0],
        });
        this.$reset();
        return false;
      } else {
        useUiStore().modalPurchaseOffer = false;
        this.isLoading = false;
        this.$reset();
        return true;
      }
    },
    setItems() {
      console.log("setItems:");
      const cart = useCartStore();
      const items: CartItem[] = [...cart.items];
      const selectedPrice = this.selected.price.discount
        ? this.selected.price.discount_price
        : this.selected.price.sell_price;
      items.push({
        item_id: this.selected.varid,
        merchant_id: this.product.merchant,
        merchant_item_id: this.selected.merchant_varid,
        name: this.product.name,
        qty: 1,
        product_fresh: false,
        unit_price: selectedPrice,
        note: "",
        dynamic_attributes: this.selected.variant,
        weight: 1,
        image_url: this.selected.image_url,
        detail_url: this.product.detail_url,
        product_slug: this.product.product_slug,
        merchant_slug: this.product.merchant_slug,
        stock_available: true,
        is_custom: false,
        is_discount: false,
        use_variant: true,
        sell_price: this.selected.price.sell_price,
        discount_price: this.selected.price.discount_price,
        total_amount: selectedPrice,
        subtotal_amount: selectedPrice,
        item_level_discounts: [],
        merchant_name: this.product.merchant.name,
        merchant_logo: this.product.merchant.logo_url,
        merchant_pixel_id: "",
        warehouse_id: this.warehouse.id,
        wh: this.warehouse,
        shipment: { courier_id: "", required: true },
        volume: this.product.volume,
        info: null,
        show_qty: true,
        editable: true,
        is_ppo: true,
      });
      this.totalOrder = cart.totalOrder + selectedPrice;
      this.items = items;
      // group cart items by merchant_id
      const groupByMid = this.items.reduce((r: any, a: any) => {
        r[a.merchant_id] = [...(r[a.merchant_id] || []), a];
        return r;
      }, {});
      this.merchantItemsMid = groupByMid;
    },
  },
  persist: [
    {
      key: "nfppo",
      storage: persistedState.localStorage,
    },
  ],
});
