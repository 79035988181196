import { useNotification } from "@kyvg/vue3-notification";
const { notify } = useNotification();

// composable functions
export const doLogout = () => {
  const ui = useUiStore();
  const router = useRouter();
  ui.modalLogout = false;
  setTimeout(async () => {
    await router.push("/logout");
  }, 100);
};

export const isCartGRoute = () => {
  const route: any = useRoute();
  return (
    route.name === "cart" || route.name === "login" || route.name === "index"
  );
};

export const isCartPRoute = () => {
  const route: any = useRoute();
  return route.name === "cart-id";
};

export const fetchCartData = async () => {
  console.log("fetchCartData:");
  const cart = useCartStore();
  const payment = usePaymentStore();
  const route: any = useRoute();
  const cartId = route.params.id || "";

  const auth = useAuthStore();
  const shipping = useShippingStore();
  const shipRequired = cart.shipment_required;

  shipping.isCalculating = true;
  payment.isLoading = true;

  const cartG = isCartGRoute();
  const cartP = isCartPRoute();
  console.log("cartP:", cartP);
  console.log("cartG:", cartG);

  // for cart general
  if (cartG) {
    await cart.getCart();
    await afterGetCart();

    payment.isLoading = false;
    shipping.isCalculating = false;
  }
  // for cart platform
  if (cartP) {
    cart.resetState();
    await cart.getCartId(cartId);
    if (!shipRequired) shipping.$reset();
    // cart for specific user
    if (cart.account.bypass_phone_input && !auth.loggedIn) {
      auth.phone = cart.account.phone;
      const otp = await auth.otpSend();
      if (otp) {
        return navigateTo({ path: "/otp" });
      }
    }
    await afterGetCart();
    payment.isLoading = false;
    shipping.isCalculating = false;
  }
};

export const afterGetCart = async () => {
  const auth = useAuthStore();
  const payment = usePaymentStore();
  const promo = usePromoStore();
  const cart = useCartStore();
  const shopper = useShopperStore();
  const shipping = useShippingStore();
  const addressId = computed(() => shopper.defAddress.address_id || "");
  const shipRequired = cart.shipment_required;
  const ui = useUiStore();

  if (!auth.loggedIn) return false;

  let queryParam = `hdo=true&hdg=true&courier_id=${ui.shipType}&cart_id=${cart.cart_id}`;
  const mid = cart.items[0]?.merchant_id;
  console.log("afterGetCart mid:", mid);

  // check totalOrder
  if (!hasLength(cart.items) && cart.totalOrder <= 0) return;
  // check merchant_id
  if (!mid) {
    console.log("merchant_id not found");
    return;
  }

  if (cart.isPlatformCart) queryParam += `&mid=${mid}`;

  console.log("afterGetCart queryParam:", queryParam);
  await payment.getListPayment(queryParam);
  await shopper.getShopper();

  // check totalOrder
  console.log("addressId:", addressId.value);
  console.log("shipRequired:", shipRequired);
  console.log("ui.shipType:", ui.shipType);
  // check shipping type
  if (shipRequired && ui.shipType === "delivery") {
    await promo.getMultiShippingPromo();
    promo.calcTotalShippingDiscount();
    // getCourierRates need default address
    if (shopper.defAddress?.address_id !== "") {
      // await shipping.getCourierRates(mid, true);
      await shipping.checkMultiCourierRates();
      // const amp = useAmplitudeStore();
      // amp.setEventProps({});
    }
  }

  // get reward for all cart type
  if (auth.loggedIn) {
    // get rewards
    await promo.getRewards(mid);

    if (hasLength(promo.appliedReward)) {
      console.log("=> afterGetCart selectRewardToApply");
      promo.selectRewardToApply(mid);
    }
    await promo.getRewardEstimation(mid);
    await payment.getCardInstallment();
  } else {
    promo.$reset();
  }
  //

  promo.calcTotalPromo();
};

// handle sharedLink on General Cart
// https://checkout.dev.useflik.com/cart?id=b0e52b5c-6584-478c-b300-a379260527cc
export const getPublicGeneralCartByID = async (cartId: string) => {
  console.log(
    "composables/reusable.ts => getPublicGeneralCartByID(cartId) =>",
    cartId
  );

  const cart = useCartStore();
  const auth = useAuthStore();

  if (auth.loggedIn) {
    await cart.replaceGeneralCartItems(cartId);
    await navigateTo({ path: "/cart" });
  } else {
    await cart.getPublicGeneralCartByID(cartId);
  }
};

export const refreshCartGeneral = async () => {
  const cart = useCartStore();
  const payment = usePaymentStore();
  const shipping = useShippingStore();
  const promo = usePromoStore();

  await cart.getCart();
  const shipType = "delivery";
  const queryParam = `hdo=true&hdg=true&courier_id=${shipType}&cart_id=${cart.cart_id}`;

  await payment.getListPayment(queryParam);
  await shipping.checkMultiCourierRates();
  // get reward for all cart type
  if (hasLength(cart.items) && cart.totalOrder > 0) {
    const mid = cart.items[0]?.merchant_id;
    if (!mid) {
      console.log("merchant_id not found");
      return;
    }
    await promo.getRewards(mid);
    if (hasLength(promo.appliedReward)) {
      promo.selectRewardToApply(mid);
    }
    await promo.getRewardEstimation(mid);
  }
  //
};

export const getPaymentTokenId = (p: any) => {
  const shopper = useShopperStore();
  const pay: any = shopper.saved_payments.find((sp: any) => sp.type === p.id);
  let token: string = pay ? pay.token_id : "";
  if (p.id === shopper.defPayment?.type) {
    token = shopper.defPayment.token_id;
  }
  return token;
};

export const isInSavedPayment = (p: any) => {
  const shopper = useShopperStore();
  const inSaved = shopper.saved_payments.some((x: any) => x.type === p.id);
  // console.log("isInSavedPayment:", inSaved);
  return inSaved;
};

export const isNeedPaymentActivation = (p: any) => {
  const arr = ["card", "bcadebit", "ovo", "gopay", "shopeepay", "dana"];
  const payIn = arr.includes(p.id);
  return payIn;
};

export const noNeedPaymentActivation = (p: any) => {
  const arr = ["card", "bcadebit", "ovo", "gopay", "shopeepay", "dana"];
  const payIn = !arr.includes(p.id);
  return payIn;
};

export const showModalPayment = async () => {
  const ui = useUiStore();
  const payment = usePaymentStore();
  const cart = useCartStore();
  const shipType = "delivery";

  // Amplitude tracker
  amplitudeTrackPageView("Checkout Select Payment Page");

  let queryParam = `hdo=true&hdg=true&courier_id=${shipType}&cart_id=${cart.cart_id}`;

  if (cart.isPlatformCart) {
    const mid = cart.items[0]?.merchant_id;
    queryParam += `&mid=${mid}`;
  }
  const gp = await payment.getListPayment(queryParam);
  if (gp) ui.modalPayment = true;
};

export const selectPayment = async (selected: any) => {
  console.log("selectPayment selected:", selected);
  const payment = usePaymentStore();
  const shopper = useShopperStore();
  const ui = useUiStore();
  const ship = useShippingStore();
  const cart = useCartStore();
  ui.modalPayment = false;

  if (selected.id === "cod") {
    const cart = useCartStore();
    const merchant_id = cart.items[0]?.merchant_id;
    payment.setTempPayment({
      ...selected,
      merchant_id,
    });
    ui.showEwalletBalance = false;
  } else {
    const arrAddToken = ["virtualaccount", "qr"];
    const token_id = arrAddToken.includes(selected.group_id)
      ? `${selected.id}_token`
      : selected.token_id;

    const haveVA = shopper.saved_payments.some((x: any) =>
      x.type.includes(`${selected.id}_virtualaccount`)
    );

    const haveQR = shopper.saved_payments.some((x: any) =>
      x.type.includes(`${selected.id}_token`)
    );

    let res: any = false;

    if (
      (!haveVA || !haveQR) &&
      (selected.id.includes("virtualaccount") || selected.id.includes("qr"))
    ) {
      const params = {
        token_id,
        type: selected.id,
        billing_address: shopper.defAddress,
        dynamic_attribute: {
          country: "ID",
        },
        is_default: true,
      };
      console.log("params:", params);
      // DEBUG
      // return;
      // DEBUG
      res = await payment.addPayment(params);
    } else {
      res = await payment.setDefaultPayment({ token_id });
    }
    console.log("selectPayment res:", res);
    if (res) {
      if (payment.tempPayment?.id) {
        payment.tempPayment = null;
      }
      await shopper.getShopper();
    }
  }

  await payment.getCardInstallment();
  // always get courier rate after change payment method
  if (shopper.defAddress?.address_id !== "") {
    const mid = cart.items[0].merchant_id;
    await ship.getCourierRates(mid, true);
  }
};

export const showModalCourier = async (merchant?: any) => {
  console.log("merchant:", merchant);

  const ship = useShippingStore();
  const ui = useUiStore();
  const cart = useCartStore();
  const promo = usePromoStore();

  let mid: string = "";
  if (merchant === "" || !merchant) {
    mid = cart.items[0].merchant_id;
  } else {
    mid = merchant;
  }

  // check shipping discount first
  await promo.getShippingPromo(mid);

  // Amplitude tracker
  amplitudeTrackPageView("Checkout Select Shipping Page");

  // check shipping discount first

  await promo.getShippingPromo(mid);

  const cp = await ship.getCourierRates(mid, false);

  if (cp) {
    ui.modalCourier = true;

    await promo.getRewards(mid);
    if (hasLength(promo.appliedReward)) {
      promo.selectRewardToApply(mid);
    }
  }

  // Amplitude tracker
  amplitudeTrackPageView("Checkout Select Shipping Page");
};

export const selectCourierMerchant = async (
  courier: any,
  merchant_id: string
) => {
  console.log("selectCourierMerchant:", courier);
  const ship = useShippingStore();
  const ui = useUiStore();
  ship.selectCourier(courier, merchant_id);
  ui.modalCourier = false;
  const promo = usePromoStore();

  promo.calcTotalShippingDiscount();
  promo.calcTotalReward();
  promo.calcTotalPromo();

  const cart = useCartStore();
  const route = useRoute();
  const payment = usePaymentStore();
  let queryParam = `hdo=true&hdg=true&cart_id=${cart.cart_id}`;
  const courier_id = "delivery";
  if (route.name === "cart-id") {
    queryParam = `hdo=true&hdg=true&cart_id=${cart.cart_id}&mid=${cart.singleMerchant}&courier_id=${courier_id}`;
  }

  trackFbCustom("AddShippingInfo", {
    content_category: "shipping",
    value: courier.price,
    courier: courier.id,
    currency: "IDR",
  });

  await payment.getListPayment(queryParam);
  await payment.getCardInstallment();
};

export const getCourierMerchant = (mid: string) => {
  const ship = useShippingStore();
  const courier = ship.selectedCourier.find((x: any) => x.merchant_id === mid);
  console.log("getCourierMerchant:", courier);
  return courier;
};

export const copyString = (str: string, copiedMsg: string) => {
  console.log("copyString:");
  const source = ref("");
  const { copy, isSupported } = useClipboard({ source });
  if (!isSupported) {
    useNotification().notify({
      type: "error",
      text: "Copy not supported",
    });
    return;
  }

  copy(str)
    .then((res) => {
      console.log("res:", res);
      useNotification().notify({
        type: "success",
        text: copiedMsg,
      });
    })
    .catch((err) => {
      console.log("err:", err);
      useNotification().notify({
        type: "error",
        text: "Copy error",
      });
    });
};

export const showAddressModal = () => {
  // Amplitude tracker
  amplitudeTrackPageView("Checkout Select Address Page");
  const ui = useUiStore();
  const address = useAddressStore();
  const shopper = useShopperStore();
  ui.modalAddress = true;
  address.toEdit = emptyAddress;
  console.log("shopper.hasAccount:", shopper.hasAccount);
  console.log("shopper.flags.is_guest:", shopper.flags.is_guest);
  if (!shopper.hasAccount && !shopper.flags.is_guest) {
    navigateTo("/profile");
  }
  if (shopper.default_address === "") {
    ui.modalFormAddress = true;
  }
};

export const showFormAddressModal = () => {
  const ui = useUiStore();
  ui.modalFormAddress = true;
  ui.modalAddress = false;
  // amplitudeTrackBtnClicked("Button Show Address", "", "");
};

export const hideConfirmDelete = (close: any) => {
  const address = useAddressStore();
  address.addToDeleteId = "";
  close();
};

export const closeModalFormAddress = () => {
  const ui = useUiStore();
  ui.modalFormAddress = false;
  ui.modalAddress = false;
};

export const closeModalAddress = () => {
  useUiStore().modalAddress = false;
  const address = useAddressStore();
  address.toEdit = emptyAddress;
};

export const setDefaultAddress = async (address: Address) => {
  const shopper = useShopperStore();
  // const shipping = useShippingStore();
  // const promo = usePromoStore();
  const addr = await shopper.putAddress(address);
  if (addr) {
    // shipping.resetSelectedCourier();
    // promo.resetShippingPromo();
    useUiStore().modalAddress = false;
    await afterGetCart();
  }
};

export const deleteAddress = async (id: string) => {
  const addr = await useAddressStore().deleteAddress(id);
  if (addr) {
    const ui = useUiStore();
    const shopper = useShopperStore();
    await shopper.getShopper();
    ui.modalAddress = false;
    useNotification().notify({
      text: "Alamat berhasil dihapus",
    });
  }
};

export const editAddress = (saddress: Address) => {
  const ui = useUiStore();
  const address = useAddressStore();
  ui.modalFormAddress = true;
  ui.modalAddress = false;
  address.toEdit = saddress;
};

export const closeModalPromo = async () => {
  const ui = useUiStore();
  ui.modalDiscount = false;
  await applyPromo();
};

export const applyPromo = async () => {
  const ui = useUiStore();
  const cart = useCartStore();
  const promo = usePromoStore();
  ui.modalDiscount = false;
  const mid = cart.singleMerchant
    ? cart.singleMerchant
    : cart.items[0].merchant_id;

  // apply reward
  promo.selectRewardToApply(mid);

  if (
    hasLength(promo.selectedFlikVoucher) ||
    hasLength(promo.selectedMerchantVoucher)
  ) {
    const vouchers = [
      ...promo.selectedFlikVoucher,
      ...promo.selectedMerchantVoucher,
    ];
    console.log("applyPromo vouchers:", vouchers);
    const apply = await promo.postVoucher(vouchers);
    console.log("applyPromo:", apply);
    if (apply) {
      notify({
        text: "Diskon berhasil diterapkan",
        type: "success",
      });
      await fetchCartData();
    }
  }
  if (mid) await promo.getRewardEstimation(mid);
};

export const applyVoucherCode = async (vcode: string) => {
  const ui = useUiStore();
  const cart = useCartStore();
  const promo = usePromoStore();
  if (vcode !== "") {
    const vouchers = [
      {
        code: vcode,
      },
    ];
    ui.modalDiscount = false;
    promo.voucherCode = "";

    const mid = cart.singleMerchant
      ? cart.singleMerchant
      : cart.items[0].merchant_id;

    // apply reward
    promo.selectRewardToApply(mid);

    const apply = await promo.postVoucher(vouchers);
    console.log("applyVoucherCode:", apply);
    if (apply) {
      notify({
        text: "Voucher code berhasil diterapkan",
        type: "success",
      });
      await fetchCartData();
    }
  }
};

export const setPickupWarehouse = (warehouse: PickupWarehouse) => {
  const ui = useUiStore();
  ui.selectedWarehouse = warehouse;
  ui.modalPickupWarehouse = false;
};

export const handlePickupStore = () => {
  const ui = useUiStore();
  const shipping = useShippingStore();
  const promo = usePromoStore();
  ui.shipType = "pickup";
  promo.resetShippingPromo();
  shipping.resetSelectedCourier();
  shipping.calcShippingPrice();
};

export const handleDelivery = async () => {
  const ui = useUiStore();
  const shipping = useShippingStore();
  const promo = usePromoStore();
  const cart = useCartStore();
  ui.shipType = "delivery";
  await promo.getMultiShippingPromo();
  const mid = cart.items[0]?.merchant_id;
  await shipping.getCourierRates(mid, true);
};

export const getFeatureFlag = async (key: any) => {
  const { FF_URL } = useRuntimeConfig().public;
  const { data } = await useFetch<any>(`${FF_URL}/v1/feature/${key}/eval`, {
    method: "POST",
    body: {
      defaultValue: false,
      user: {
        anonymous: false,
        key: "guest",
        custom: {
          env_name: process.env.NODE_ENV !== "production" ? "dev" : "prod",
        },
      },
    },
  });
  console.log(`getFeatureFlag ${key}:`, data.value);

  if (data.value?.value) return data.value?.value;
  else return false;
};

export const getFeatureFlagWithTargeting = async (key: any, custom: any) => {
  const { FF_URL } = useRuntimeConfig().public;
  const { data } = await useFetch<any>(`${FF_URL}/v1/feature/${key}/eval`, {
    method: "POST",
    body: {
      defaultValue: false,
      user: {
        anonymous: false,
        key: "guest",
        custom,
      },
    },
  });
  console.log(`getFeatureFlagWithTargeting ${key}:`, data.value);

  if (data.value?.value) return data.value?.value;
  else return false;
};
