import { defineStore } from "pinia";
import { useNotification } from "@kyvg/vue3-notification";
import { getLowestPrice } from "~/utils/helper";

export const useShippingStore = defineStore("shipping", {
  state: () => ({
    isLoading: false,
    isCalculating: false,
    totalPrice: 0,
    totalAppliedPrice: 0,
    totalAppliedDiscount: 0,
    requestedMid: "",
    singleCourier: defaultCourier as any,
    lowestCost: null as any,
    selectedCourier: [] as PickCourier[],
    listCourier: [] as CourierItem[],
    errMsg: [] as any[],
  }),
  getters: {
    countSelected: (state) => {
      return state.selectedCourier.length;
    },
  },
  actions: {
    setShippingLoading(value: boolean) {
      this.isLoading = value;
    },
    setCalculating(value: boolean) {
      this.isCalculating = value;
    },
    setRequestMid(val: string) {
      this.requestedMid = val;
    },
    resetListCourier() {
      this.$patch((state) => {
        state.listCourier = [];
      });
    },
    resetSelectedCourier() {
      this.$patch((state) => {
        state.lowestCost = defaultCourier;
        state.selectedCourier = [];
        state.totalPrice = 0;
        state.totalAppliedPrice = 0;
      });
    },
    calcShippingPrice() {
      // console.log("calcShippingPrice => ");
      const cart = useCartStore();
      this.setCalculating(true);

      let sumCourier = 0;
      let sumShipDisc = 0;

      for (const sc of this.selectedCourier) {
        if (sc?.price) {
          sumCourier += sc.price;
        }
        console.log("calcShippingPrice sc:", sc);
        if (sc?.price_discount_applied) {
          sumShipDisc += sc.price_discount_applied;
        }
      }

      console.log("calcShippingPrice sumCourier:", sumCourier);
      this.totalPrice = sumCourier;
      this.totalAppliedPrice = sumCourier - sumShipDisc;
      this.totalAppliedDiscount = sumShipDisc;

      cart.addTotalOrder(this.totalAppliedPrice);

      this.setCalculating(false);
    },
    setListCourier(data: any[], merchant_id: string) {
      const item = data.find((d: any) => d.merchant_id === merchant_id);
      const merchantName = item.merchant_name || merchant_id;

      const scInSelected: any = this.selectedCourier.find(
        (sc: any) => sc.merchant_id === merchant_id,
      );

      console.warn(
        `=> setListCourier ${merchantName} scInSelected`,
        scInSelected,
      );

      if (!scInSelected) {
        // find lowest courier cost for single merchant
        const lowestCost = getLowestPrice(data);
        console.warn(
          `=> setListCourier lowestCost ${merchantName} lowestCost:`,
          lowestCost,
        );

        const lowestInSelected: any = this.selectedCourier.find(
          (sc: any) => sc.id === lowestCost.id,
        );
        console.log(
          `=> setListCourier ${merchantName} lowestCostInSelected :`,
          lowestInSelected,
        );

        // assign lowestCost
        if (lowestInSelected?.merchant_id !== merchant_id) {
          this.selectCourier(lowestCost, merchant_id);
        }
      }

      // update selected courier price if exist
      if (this.selectedCourier.length > 0) {
        console.log(
          "=> setListCourier update selectedCourier if exist in new data",
          data,
        );
        for (const sc of this.selectedCourier) {
          // find courier in data
          const ncourier = data.find((d: any) => d.id === sc.id);
          if (ncourier) {
            console.log("setListCourier scInSelected:", scInSelected);
            console.log("setListCourier ncourier:", ncourier);
            console.warn(
              `setListCourier selected courier in new data ${merchant_id}`,
            );
            if (ncourier.price !== scInSelected?.price) {
              this.removeCourier(ncourier.merchant_id);
            }
            this.selectCourier(ncourier, merchant_id);
          } else {
            console.warn(
              `setListCourier ${sc.title} not in new data, RESET selectedCourier`,
            );

            const cart = useCartStore();

            const scInCart = cart.items.find(
              (c: any) => c.merchant_id === sc.merchant_id,
            );

            console.log("setListCourier scInCart:", scInCart);

            if (!scInCart && cart.merchantCount === 1)
              this.removeCourier(sc.merchant_id); // TODO check what need to remove
            // Set new lowest cost
            const lowestCostNew = getLowestPrice(data);
            if (lowestCostNew) {
              this.selectCourier(lowestCostNew, merchant_id);
            }
          }
        }
      }

      // check selected courier
      this.removeUnrelatedCourier();
      // check selected courier

      this.calcShippingPrice();
      console.log("\n\n\n");
      // find lowest courier cost for single merchant
    },
    selectCourier(courier: any, merchant_id?: string) {
      const scourier = { ...courier, merchant_id };
      console.log("=> selectCourier scourier:", scourier);

      const findInSelected = this.selectedCourier.find(
        (sc: any) =>
          sc.merchant_id === scourier.merchant_id && scourier.id === sc.id,
      );
      console.log("=> selectCourier findInSelected:", findInSelected);

      if (typeof findInSelected === "undefined") {
        this.removeCourier(scourier.merchant_id);
        this.selectedCourier.push(scourier);
      }
      this.setRequestMid("");
      this.calcShippingPrice();
    },
    removeCourier(merchant_id: string) {
      // console.log("=> removeCourier:", merchant_id);
      this.$patch((state) => {
        state.selectedCourier = state.selectedCourier.filter(
          (c: any) => c.merchant_id !== merchant_id,
        );
      });
      this.calcShippingPrice();
    },
    removeUnrelatedCourier() {
      console.log("=> removeUnrelatedCourier");
      const cart = useCartStore();
      this.selectedCourier.forEach((sc: any) => {
        const inCartItems = cart.items.some(
          (x: any) => x.merchant_id === sc.merchant_id,
        );
        // in scourier merchant_id not in cart remove it
        if (!inCartItems) {
          console.log(
            `=> removeUnrelatedCourier removed ${sc.id} ${sc.merchant_id}:`,
            sc,
          );
          this.removeCourier(sc.merchant_id);
        }
        if (!sc?.id) {
          this.removeCourier(sc.merchant_id);
        }
      });
    },
    async getCourierRates(merchant_id: string, auto?: boolean) {
      const cart = useCartStore();

      const itemMerchant = cart.items.find(
        (x: any) => x.merchant_id === merchant_id,
      );

      if (itemMerchant)
        console.log(
          `=> getCourierRates ${itemMerchant?.merchant_name} merchant_id:`,
          merchant_id,
        );

      this.setRequestMid(merchant_id);

      this.setShippingLoading(true);

      const shopper = useShopperStore();
      // console.log("getCourierRates shopper.defAddress:", shopper.defAddress);
      // const defAddress: any = shopper.defAddress;

      let origin: any = {};
      const items: any[] = [];

      const merchantItems = cart.merchantItemsMid[merchant_id];
      if (hasLength(merchantItems)) {
        for (const mi of merchantItems) {
          items.push({
            name: mi.name,
            description: mi.name,
            length: mi.volume.length,
            width: mi.volume.width,
            height: mi.volume.height,
            weight: mi.weight,
            quantity: mi.qty,
            value: mi.sell_price,
          });
        }
      }

      const wh = merchantItems[0]?.wh;
      if (wh) {
        origin = {
          postal_code: parseInt(wh.postal_code),
          lat: wh.lat,
          lng: wh.lng,
          city: wh.city,
        };
      }

      const destination = {
        postal_code: parseInt(shopper.defAddress?.postal_code) || 0,
        lat: shopper.defAddress?.lat || 0,
        lng: shopper.defAddress?.lng || 0,
        city: shopper.defAddress?.city || "",
      };

      let payment_type = shopper.defPayment?.type || "";
      const tempPayment = usePaymentStore().tempPayment;
      if (tempPayment && tempPayment?.id === "cod") {
        payment_type = tempPayment.id;
      }

      const payload: any = {
        cart_id: cart.cart_id,
        merchant_id,
        payment_type,
        items,
        destination,
        origin,
      };
      // console.log(`=> getCourierRates ${merchant_id}:`);

      const { $api: api } = useNuxtApp();
      const { data: resp, status: reqStatus } =
        await api.shipping.getRates(payload);

      const courierData: any[] = resp.value?.data || [];

      if (reqStatus.value === "success" && courierData?.length > 0) {
        const cartItem: any = cart.items.find(
          (ci: any) => ci.merchant_id === merchant_id,
        );

        const promo = usePromoStore();

        console.log(
          "getCourierRates totalShippingDiscount:",
          promo.totalShippingDiscount,
        );

        let mShipDiscAmount = 0;

        const mCourierInShipDisc = promo.shippingDiscounts.find(
          (sd: any) => sd.mid === merchant_id,
        );

        console.log(
          `getCourierRates ${itemMerchant?.merchant_name} mCourierInShipDisc:`,
          mCourierInShipDisc,
        );

        if (hasLength(promo.shippingDiscounts)) {
          const merchantShipDisc: any = promo.shippingDiscounts.find(
            (sd: any) => sd.mid === merchant_id,
          );
          mShipDiscAmount = merchantShipDisc?.discount_amount || 0;
        }

        console.log(
          `getCourierRates ${itemMerchant?.merchant_name} mShipDiscAmount:`,
          mShipDiscAmount,
        );
        const couriers = courierData.map((x: any) => {
          let price_discount = 0;
          let price_discount_applied = 0;
          const price = x.price;

          if (mShipDiscAmount < x.price) {
            price_discount = x.price - mShipDiscAmount;
          }

          if (mShipDiscAmount >= x.price) {
            price_discount = x.price;
          }

          if (mShipDiscAmount === 0) {
            price_discount = 0;
          }

          if (price_discount > 0)
            price_discount_applied = price - price_discount;

          if (price_discount === price) price_discount_applied = price;

          return {
            id: x.id,
            courier_logo: x.courier_logo,
            is_enabled: x.is_enabled,
            merchant_id,
            merchant_name: cartItem?.merchant_name,
            price,
            price_discount,
            price_discount_applied,
            shipment_duration_range: x.shipment_duration_range || "",
            shipment_duration_unit: x.shipment_duration_unit || "",
            title: x.title,
            warehouse_id: cartItem?.warehouse_id,
          };
        });

        this.listCourier = couriers;

        console.log("auto:", auto);
        if (!auto) this.calcShippingPrice();
        if (auto) {
          this.setListCourier(couriers, merchant_id);
        }

        this.setShippingLoading(false);
        this.setCalculating(false);
        return true;
      } else {
        this.resetListCourier();
        this.resetSelectedCourier();
      }

      console.log("courierData:", courierData.length);
      if (courierData.length === 0) {
        this.removeCourier(merchant_id);
        usePromoStore().keepShippingDiscount(merchant_id);
        usePromoStore().calcTotalPromo();
        const cItem = cart.items.find(
          (c: any) => c.merchant_id === merchant_id,
        );

        let merchantName = "merchant ini";

        if (cItem) {
          merchantName = cItem.merchant_name;
        }

        const msg = `Kurir tidak tersedia untuk ${merchantName}, ganti alamat atau hubungi Seller.`;

        this.errMsg = [
          {
            msg: "Ganti alamat atau hapus produk",
            merchant_id,
          },
        ];

        scrollToTop();

        useNotification().notify({
          type: "error",
          text: msg,
        });
      }
      // update courier price if previously selected
      this.setShippingLoading(false);
      this.setCalculating(false);
      return false;
    },
    async checkMultiCourierRates() {
      this.setShippingLoading(true);

      const cart = useCartStore();

      for (const mid of cart.merchantIds) {
        const cItem = cart.items.find((c: any) => c.merchant_id === mid);
        console.log(`checkMultiCourierRates mid:`, mid);
        console.log(
          `checkMultiCourierRates merchant_name:`,
          cItem?.merchant_name,
        );
        await this.getCourierRates(mid, true);
      }

      this.setShippingLoading(false);
    },
    async checkWorkingHours() {
      this.isLoading = true;
      const { SHIPPINGSVC } = useRuntimeConfig().public;
      const shipping = this.selectedCourier.map((c: any) => {
        return {
          courier_id: c.id,
          shipping_amount: c.price,
          warehouse_id: c.warehouse_id,
        };
      });

      const payload = {
        shipping,
      };
      // console.log("getShippingPromo merchant_id", payload);
      const { data, error } = await useFlikApi<ApiResponse>(
        `${SHIPPINGSVC}/v1/working_hours/merchant`,
        { method: "POST", body: payload },
      );
      const resData: any = data?.value?.data || null;
      console.log("resData data:", resData);
      const resErrors = error?.value?.data?.errors || [];
      console.log("resErrors error:", resErrors);
      if (hasLength(resErrors)) {
        useNotification().notify({
          type: "error",
          text: resErrors[0],
        });
        this.isLoading = false;
        return false;
      }
    },
  },
  persist: [
    {
      key: "nfship",
      paths: ["selectedCourier"],
      storage: persistedState.localStorage,
    },
  ],
});
