export const shuffleArray = (array: any[]) => {
  for (let i = array.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [array[i], array[j]] = [array[j], array[i]];
  }
  return array;
};

export const shuffleFisherYates = (arr: any[]) => {
  const a: any[] = [...arr];
  let m = a.length;
  let i;
  while (m) {
    i = ~~(Math.random() * m--);
    [a[m], a[i]] = [a[i], a[m]];
  }
  return a;
};

export const setAltImg = (event: any, strAlt: string, w: number, h: number) => {
  const alt = strAlt.split(" ");
  const txtAlt = alt.length > 1 ? `${alt[0]} ${alt[1]}` : alt[0];
  // use only for plain tag <img>
  event.target.src = `https://placehold.co/${w}x${h}/eaeaea/909090?text=${txtAlt}&font=roboto`;
  console.log("event.target.src:", event.target.src);
};

export const setPlaceholderImg = (strAlt: string, w: number, h: number) => {
  const alt = strAlt.split(" ");
  const txtAlt = alt.length > 1 ? `${alt[0]} ${alt[1]}` : alt[0];
  // only for plain tag <nuxt-img>
  const src: string = `https://placehold.co/${w}x${h}/eaeaea/909090?text=${txtAlt}&font=roboto`;
  return src;
};

export const strCapitalized = (word: string) => {
  return word.charAt(0).toUpperCase() + word.slice(1);
};

export const getEntries = (object: any) => {
  return Object.keys(object).map(function (key) {
    return [key, object[key]];
  });
};

export const formatRupiah = (money: number) => {
  return new Intl.NumberFormat("id-ID", {
    style: "currency",
    currency: "IDR",
    minimumFractionDigits: 0,
  })
    .format(money)
    .replace(/\s+/g, "");
};

export const hasLength = (arr: any[]): boolean => {
  return arr && arr.length > 0;
};

export const getSelectedById = (id: string, idToCompare: string, data: any) => {
  const selected: any = data.find((s: any) => s[id] === idToCompare);
  return selected;
};

export const getChildren = (id: string, data: any) => {
  const p =
    (data || [])
      .flatMap((x: any) => x.children)
      .find((d: any) => d?.id.toLowerCase() === id) || null;
  return p;
};

export const isCustomDomain = () => {
  const { APP_DOMAIN } = useRuntimeConfig().public;
  const url = useRequestURL();
  const hostname = url.hostname;
  let isCustom = false;
  isCustom = APP_DOMAIN.includes(hostname);
  return isCustom;
};

export const truncateString = (
  str: string,
  maxlength: number,
  suffix: string,
) => {
  if (str.length > maxlength) {
    return str.substring(0, maxlength) + suffix;
  } else {
    return str;
  }
};

export const addClassBody = (name: string) => {
  const el = document.body;
  el.classList.add(name);
};

export const removeClassBody = (name: string) => {
  const el = document.body;
  el.classList.remove(name);
};

export const getLowestPrice = (data: any) => {
  const min = (a: any, f: any) =>
    a.reduce((m: any, x: any) => (m[f] < x[f] ? m : x));
  const lowestCost = min(data, "price");
  return lowestCost;
};

export const toggleClassBody = (name: string) => {
  const el = document.body;
  if (el.classList.contains(name)) {
    el.classList.add(name);
  } else {
    el.classList.remove(name);
  }
};

export const mapItemsCart = (cartItems: any) => {
  return cartItems.map((i: any, idx: number) => {
    return {
      item_id: i.item_id,
      item_name: i.name,
      currency: "IDR",
      discount: i.is_discount ? i.subtotal_amount - i.total_amount : 0,
      index: idx,
      item_brand: i.merchant_name,
      merchant: i.merchant_name,
      item_variant: i?.dynamic_attributes
        ? Object.keys(i.dynamic_attributes).join(", ")
        : "",
      price: i.subtotal_amount,
      value: i.total_amount,
      quantity: i.qty,
    };
  });
};

export const checkFBPixel = () => {
  const { FB_PIXEL_ID } = useRuntimeConfig().public;
  const cartMetaPixel = useCartStore().metadata.merchant_pixel_ids || [];
  console.log("checkFBPixel - cartMetaPixel:", cartMetaPixel);
  let pixelId = FB_PIXEL_ID;
  if (hasLength(cartMetaPixel)) {
    pixelId = cartMetaPixel[0];
  }

  if (pixelId === "") {
    console.warn("FB_PIXEL_ID empty");
    return false;
  }
  return true;
};

export const setPixelId = async (pixelId?: string) => {
  const app: any = useNuxtApp();
  const { FB_PIXEL_ID } = useRuntimeConfig().public;
  const cartMetaPixel = useCartStore().metadata.merchant_pixel_ids || [];
  console.log("setPixelId cartMetaPixel[0]:", cartMetaPixel);
  console.log("setPixelId FB_PIXEL_ID:", FB_PIXEL_ID);

  let fbPixelId: any = FB_PIXEL_ID;

  if (!app.$fb.isEnabled) {
    await app.$fb.enable();
    console.log("setPixelId enable app.$fb:", app.$fb);
  }

  if (pixelId !== "") {
    fbPixelId = pixelId;
  }

  if (hasLength(cartMetaPixel)) {
    cartMetaPixel.forEach((id) => {
      fbPixelId = id;
      console.log("setPixelId forEach cartMetaPixel:", fbPixelId);
      if (fbPixelId !== "") app.$fb.setPixelId(fbPixelId);
    });
  }
};

export const setUserData = async () => {
  const app: any = useNuxtApp();
  console.log("setUserData - checkFBPixel():", checkFBPixel());
  if (!checkFBPixel()) return;

  const auth = useAuthStore();
  const shopper = useShopperStore();
  const shopperAcc = shopper.account;

  console.log(
    "setUserData shopper.hasAccount && auth.loggedIn:",
    shopper.hasAccount && auth.loggedIn,
  );

  console.log("setUserData app.$fb:", app.$fb);

  if (shopper.hasAccount && auth.loggedIn) {
    await app.$fb.setUserData({
      em: shopperAcc.email,
      fn: shopperAcc.first_name,
      ln: shopperAcc.last_name,
      ph: shopperAcc.phone,
    });
    if (!app.$fb.isEnabled) await app.$fb.enable();
  }
};

export const trackFb = async (event_name: string, params: any) => {
  // if no pixelId cancel tracking
  if (!checkFBPixel()) return;

  const app: any = useNuxtApp();
  await setUserData();
  await setPixelId();
  if (app.$fb.isEnabled) {
    await app.$fb.track(event_name, params);
  }
};

export const trackFbCustom = async (event_name: string, params: any) => {
  // if no pixelId cancel tracking
  if (!checkFBPixel()) return;
  await setUserData();
  await setPixelId();
  const app: any = useNuxtApp();

  let eventID = useCartStore().cart_id + `_${event_name}`;
  if (event_name === "PaymentPending") {
    eventID = useTransactionStore().trxId + `_${event_name}`;
  }
  console.log(`trackFbCustom ${event_name} params:`, params);
  await app.$fb.query("trackCustom", event_name, params, { eventID });
};

export const trackFbCart = async (event_name: string) => {
  console.log("trackFbCart:", event_name);
  // if no pixelId cancel tracking
  if (!checkFBPixel()) return;
  await setUserData();
  await setPixelId();
  console.log("trackFbCart:", event_name);
  const app: any = useNuxtApp();
  console.log("trackFbCart:", app.$fb);

  const cart = useCartStore();
  const trx = useTransactionStore();

  const cartItems = event_name === "Purchase" ? trx.lastCartItems : cart.items;

  const num_items = cartItems.length;
  console.log("cartItems:", cartItems);

  if (num_items > 0) {
    let eventID = useCartStore().cart_id + `_${event_name}`;

    if (event_name === "Purchase") {
      eventID = trx.trxId + `_${event_name}`;
    }

    const value = event_name === "Purchase" ? trx.lastTotal : cart.totalOrder;
    const content_ids = cart.items.map((i: any) => i.item_id);
    let contents = mapItemsCart(cart.items);

    if (event_name === "Purchase") {
      contents = mapItemsCart(trx.lastCartItems);
    }

    const params = {
      transaction_id: trx.trxId,
      order_id: trx.trxId,
      content_category: cartItems[0].merchant_name,
      content_type: "product",
      content_ids,
      contents,
      currency: "IDR",
      num_items,
      value,
    };

    console.log(`trackFbCart ${event_name} params:`, params);

    await app.$fb.track(event_name, params, { eventID });
  }
};

export const scrollToTop = () => {
  if (process.client) window.scrollTo({ top: 0, behavior: "auto" });
};

export const getNote = (warehouse_id: string, listNotes: any[]) => {
  const note = listNotes.find((n: any) => n.warehouse_id === warehouse_id);
  return note;
};
