import { defineStore } from "pinia";
import { useNotification } from "@kyvg/vue3-notification";

export const usePaymentStore = defineStore("payment", {
  state: () => ({
    isLoading: false,
    ewalletBalance: 0,
    listGroup: [],
    list: [] as any[],
    tempPayment: null as any,
    installmentList: [] as any[],
    selectedInstallment: null as any,
  }),
  actions: {
    setPaymentLoading(value: boolean) {
      this.isLoading = value;
    },
    savePayList(arr: any[]) {
      this.$patch((state) => {
        arr.forEach((a) => {
          state.list.push(a);
        });
        const makeUnique = [...new Set(state.list)];
        state.list = makeUnique;
      });
    },
    async setDefaultPayment(payload: any) {
      const { $api: api } = useNuxtApp();
      const { data: resp, status: reqStatus } =
        await api.payment.setDefault(payload);
      console.log("setDefaultPayment resp:", resp);
      console.log("setDefaultPayment reqStatus:", reqStatus);
      if (reqStatus.value === "success") return true;
    },
    async addPayment(payload: any) {
      const { $api: api } = useNuxtApp();
      const { data: resp, status: reqStatus } = await api.payment.add(payload);
      console.log("addPayment resp:", resp);
      console.log("addPayment reqStatus:", reqStatus);
      const payErrors = resp.value?.data?.errors || [];
      console.log("payErrors:", payErrors);
      if (hasLength(payErrors)) {
        useNotification().notify({
          type: "error",
          text: payErrors[0],
        });
        return false;
      }
      if (reqStatus.value === "success" && payErrors.length === 0) {
        trackFb("AddPaymentInfo", {
          content_category: "payment method",
          payment_type: payload.type,
          value: payload.type,
          currency: "IDR",
        });
        return true;
      }
    },
    async removePayment(tokenId: string) {
      const { SHOPPERSVC } = useRuntimeConfig().public;
      const { data } = await useFlikApi<ApiResponse>(
        `${SHOPPERSVC}/v1/shopper/payment?token_id=${tokenId}`,
        {
          method: "DELETE",
        }
      );
      const payData = data?.value?.data || null;
      console.log("removePayment data:", payData);
      const payErrors = data?.value?.errors || [];
      console.log("removePayment error:", payErrors);
      if (payErrors.length === 0) return true;
    },
    async getListPayment(queryParam: string) {
      const auth = useAuthStore();
      if (auth.loggedIn) {
        this.setPaymentLoading(true);
        const { $api: api } = useNuxtApp();
        const { data: resp } = await api.payment.getList(queryParam);
        // console.log("getListPayment reqStatus:", reqStatus);
        // console.log("getPayment resp:", resp);
        const payData: any = resp.value?.data || [];
        // console.log("payData:", payData);
        if (hasLength(payData)) {
          this.listGroup = payData.sort(function (a: any, b: any) {
            const keyA = a.order;
            const keyB = b.order;
            // Compare
            if (keyA < keyB) return -1;
            if (keyA > keyB) return 1;
            return 0;
          });

          const listPayment: any[] = [];
          for (const pg of payData) {
            await pg.children.forEach((p: any) => {
              listPayment.push({
                id: p.id,
                img: p.image,
                title: p.title,
              });
            });
          }
          console.log("listPayment:", listPayment);
          this.savePayList(listPayment);
          const tempPaymentInGroup = await getChildren(
            this.tempPayment?.id,
            this.listGroup
          );
          console.log("tempPaymentInGroup:", tempPaymentInGroup);
          // if (!tempPaymentInGroup) {
          //   this.tempPayment = null;
          // }
          this.setPaymentLoading(false);
          return true;
        } else {
          this.$patch((state) => {
            state.listGroup = [];
          });
          this.setPaymentLoading(false);
          return true;
        }
      }
      this.setPaymentLoading(false);
    },
    async deletePayment(id: string) {
      const { $api: api } = useNuxtApp();
      const { data: resp, status: reqStatus } =
        await api.payment.deletePayment(id);
      console.log("deletePayment resp:", resp);
      console.log("deletePayment reqStatus:", reqStatus);
      if (reqStatus.value === "success") return true;
    },
    async getEwalletBalance(token_id: string) {
      this.isLoading = true;
      const { SHOPPERSVC } = useRuntimeConfig().public;

      const { data, error } = await useFlikApi<ApiResponse>(
        `${SHOPPERSVC}/v1/shopper/payment/latest_balance?token_id=${token_id}`
      );
      const balanceData: any = data?.value?.data || null;
      console.log("balanceData data:", balanceData);
      const balanceStatus = data?.value?.status || [];
      console.log("balanceStatus:", balanceStatus);
      const balanceErrors = error?.value?.data?.errors || [];
      console.log("balanceErrors:", balanceErrors);

      if (balanceStatus === "success" && balanceErrors.length === 0) {
        this.ewalletBalance = balanceData.balance;
        this.isLoading = false;
        return true;
      }
      this.isLoading = false;
    },
    async getCardInstallment() {
      const shopper = useShopperStore();
      this.isLoading = true;
      const { INST_MIN_ORDER } = useRuntimeConfig().public || 500000;
      const minOrder = parseInt(INST_MIN_ORDER);
      console.log("getCardInstallment minOrder:", minOrder);

      const cart = useCartStore();

      const totalOrder = cart.totalOrder;

      console.log("getCardInstallment defPayment", shopper.defPayment?.type);

      const ccInstallCond =
        shopper.defPayment?.type === "card" && totalOrder >= minOrder;

      console.log("getCardInstallment ccInstallCond:", ccInstallCond);

      if (ccInstallCond) {
        const cardToken = shopper.defPayment?.token_id;
        const merchant_ids = cart.items[0].merchant_id;
        const { PAYMENTSVC } = useRuntimeConfig().public;

        const { data, error } = await useFlikApi<ApiResponse>(
          `${PAYMENTSVC}/v1/pym/card-installments?amount=${totalOrder}&token_id=${cardToken}&merchant_ids=${merchant_ids}`
        );
        const installmentData: any = data?.value?.data || null;
        console.log("installmentData data:", installmentData);
        const installmentStatus = data?.value?.status || [];
        console.log("installmentStatus:", installmentStatus);
        const installmentError = error?.value?.data?.errors || [];
        console.log("installmentError:", installmentError);

        if (installmentStatus === "success" && installmentError.length === 0) {
          if (hasLength(installmentData?.installments)) {
            this.installmentList = installmentData.installments;
            this.selectedInstallment = installmentData.installments[0];
          } else {
            this.installmentList = [];
            this.selectedInstallment = null;
          }
          this.isLoading = false;
          return true;
        } else {
          this.installmentList = [];
          this.selectedInstallment = null;
          this.isLoading = false;
          return false;
        }
      } else {
        this.installmentList = [];
        this.selectedInstallment = null;
        this.isLoading = false;
        return false;
      }
    },
    selectInstallment(param: PaymentInstallment) {
      this.selectedInstallment = param;
    },
    setTempPayment(payment: any) {
      this.tempPayment = payment;
    },
  },
  persist: [
    {
      key: "nfpays",
      paths: ["list", "selectedInstallment", "tempPayment"],
      storage: persistedState.localStorage,
    },
  ],
});
