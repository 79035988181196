import { defineStore } from "pinia";

export const useUiStore = defineStore("ui", {
  state: () => ({
    homePath: "/cart",
    homeQuery: {},
    toastVisible: false,
    toastMessage: "",
    toastTimer: 0,
    toastType: "",
    keywordWarehouse: "",
    shipType: "delivery",
    selectedWarehouse: null as PickupWarehouse | null,
    pickupWarehouse: [] as PickupWarehouse[],
    toastPersist: false,
    accountLoading: false,
    showTimer: true,
    showListCourier: false,
    modalCourier: false,
    modalPayment: false,
    modalAddress: false,
    modalAddAddress: false,
    modalFormAddress: false,
    modalDiscount: false,
    modalUnlinkEwallet: false,
    notifPotentialReward: false,
    modalPotentialReward: false,
    modalPickupWarehouse: false,
    modalPurchaseOffer: false,
    modalLogout: false,
    modalGuestConfirm: false,
    modalGuestPhone: false,
    modalGuestOTP: false,
    modalGuestRegisterComplete: false,
    shouldTimerPaymentRestart: false,
    showEwalletBalance: false,
  }),
  actions: {
    setAccountLoading(status: boolean) {
      this.accountLoading = status;
    },
    setShowTimer(val: boolean) {
      this.showTimer = val;
    },
    showToast(
      message: string,
      type: string,
      timer = 5000,
      toastPersist = false
    ) {
      this.toastVisible = true;
      this.toastMessage = message;
      this.toastTimer = timer;
      this.toastType = type;
      this.toastPersist = toastPersist;
      if (process.client) {
        window.scrollTo({
          top: 0,
          left: 0,
          behavior: "smooth",
        });
      }
    },
    closeToast() {
      this.toastPersist = false;
      this.toastVisible = false;
      this.toastMessage = "";
      this.toastType = "";
    },
    resetPickupWarehouse() {
      this.selectedWarehouse = null;
      this.shipType = "delivery";
    },
  },
  persist: [
    {
      key: "nfui",
      paths: ["homePath", "homeQuery", "shipType", "selectedWarehouse"],
      storage: persistedState.localStorage,
    },
  ],
});
